import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import React, { useState } from "react";
import PhotoAlbum from "react-photo-album"
import { graphql } from "gatsby"
import Lightbox from "yet-another-react-lightbox"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import Captions from "yet-another-react-lightbox/plugins/captions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlickr, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import Seo from "../components/seo";
import Layout from "../components/layout";
import photosData from "../data/photos.json";
import PageTitle from "../components/page_title";

const photos = [...photosData].reverse();

const Photos = () => {
  const [index, setIndex] = useState(-1);

  return (
    <Layout>
      <header className="mb-5">
        <div>
          <PageTitle>Photos</PageTitle>

          <div className="rounded shadow-lg bg-surface p-3 text-xs">
            <p className="mb-1">
              {"Photos of some of my adventures and when I don't forget to take my camera with me"}
            </p>

            <ul className="list-disc ml-3 mb-0 text-thin">
              <li>
                {"All content is licensed by "}
                <a
                  rel="license"
                  href="http://creativecommons.org/licenses/by-sa/4.0/"
                  className="text-link"
                >
                  {"Creative Commons Attribution-ShareAlike 4.0 International License"}
                </a>
                {"."}
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.flickr.com/photos/demogar/"
                  rel="noreferrer"
                  title="Flickr profile"
                  className="text-link"
                >
                  <FontAwesomeIcon icon={faFlickr} />
                  {" Check more photos on Flickr"}
                </a>
                {" or "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/demogar/"
                  rel="noreferrer"
                  title="Instagram profile"
                  className="text-link"
                >
                  <FontAwesomeIcon icon={faInstagramSquare} />
                  {" Follow me on Instagram"}
                </a>
                {"."}
              </li>
              <li>
                <strong>Cameras:</strong>
                {" Sony a6000, Sony a7III, DJI Mini 2."}
              </li>
              <li>
                <strong>Lenses:</strong>
                {" Sigma 16mm f1.4 DC DN, Sony FE 18mm f1.8, Sony FE 50mm f1.8, Viltrox 85mm f1.8, Canon 18-135mm f3.5-5.6."}
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div id="photos">
        <PhotoAlbum layout="rows" photos={photos} onClick={({ index }) => setIndex(index)} />
        <Lightbox
          slides={photos.map(x => ({
            src: x.full,
            title: x.title,
            description: x.description,
          }))}
          index={index}
          close={() => setIndex(-1)}
          open={index > -1}
          plugins={[Fullscreen, Zoom, Captions]}
        />
      </div>
    </Layout>
  );
}

export default Photos;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Photos" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
